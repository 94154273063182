import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';

const uses = () => {
  return (
    <Layout>
      <Head pageTitle="Uses" />
      <h1>Hi this page is temporary, i will update this ASAP!</h1>
    </Layout>
  );
};

export default uses;
